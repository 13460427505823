var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-no-padding",attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-2"},[_vm._v("Plano de Assinatura de Conteúdo")])])],1),_c('v-row',[_c('v-col',{staticClass:"col-no-padding",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.planos,"loading":_vm.loading,"loading-text":"Carregando...","hide-default-footer":"","width":"100%"},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"subheading font-weight-light text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.nome))]),_c('td',[_vm._v(_vm._s(item.descricao))]),_c('td',[_vm._v(_vm._s(item.periodicidade.descricao))]),_c('td',[_vm._v(_vm._s(_vm.asMoney(item.valorTotal)))]),_c('td',[_vm._v(_vm._s(_vm.asMoney(item.valorDesconto)))]),_c('td',[_vm._v(_vm._s(_vm.asMoney(item.valor)))]),_c('td',{staticClass:"text-right"},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"inset":"","hide-details":"","disabled":""},model:{value:(item.isDisponivel),callback:function ($$v) {_vm.$set(item, "isDisponivel", $$v)},expression:"item.isDisponivel"}})],1),_c('td',{staticClass:"text-right"},[(!item.isSemCustos)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Editar"},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v("mdi-pencil")]):_vm._e()],1)])}),0)]}}])})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"subtitle-2"},[_vm._v("Conteúdo Exclusivo")]),_c('vuetify-money',{attrs:{"label":"Valor padrão para conteúdo exclusivo","properties":{
            loading: _vm.loadingConteudoExclusivo
          }},model:{value:(_vm.valorConteudoExclusivo),callback:function ($$v) {_vm.valorConteudoExclusivo=$$v},expression:"valorConteudoExclusivo"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-10 ",attrs:{"loading":_vm.loadingConteudoExclusivo,"small":"","color":"primary"},on:{"click":_vm.salvarConteudoExclusivo}},[_vm._v(" Salvar ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"subtitle-2"},[_vm._v("Gorjeta")]),_c('vuetify-money',{attrs:{"label":"Valor mínimo para gorjetas","properties":{
            loading: _vm.loadingGorjeta
          }},model:{value:(_vm.valorDefaultGorjeta),callback:function ($$v) {_vm.valorDefaultGorjeta=$$v},expression:"valorDefaultGorjeta"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-10",attrs:{"loading":_vm.loadingGorjeta,"small":"","color":"primary"},on:{"click":_vm.salvarDefaultGorjeta}},[_vm._v(" Salvar ")])],1)],1),(_vm.dialog)?_c('EditPlano',{attrs:{"close-dialog":_vm.closeDialog,"item":_vm.plano,"callback":_vm.salvar}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }