<template>
  <div class="text-center">
    <v-dialog value="true" persistent width="600px">
      <template v-if="loading">
        <v-row no-gutters style="height: 90vh;" class="text-center">
          <v-col align-self="center">
            <v-progress-circular indeterminate color="primary" :size="70" />
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-card>
          <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
            <v-toolbar-title>
              <span class="headline">Alterar valores do plano</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon large @click="closeDialog">mdi-close</v-icon>
          </v-toolbar>
          <v-row no-gutters class="pa-2">
            <v-col md="12" cols="12">
              <v-card elevation="0">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <span class="subtitle-2">Valor total</span>
                      <vuetify-money
                        v-model="item.valorTotal"
                        :disabled="readOnly"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span class="subtitle-2">Valor desconto</span>
                      <vuetify-money
                        v-model="item.valorDesconto"
                        :disabled="readOnly"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span class="subtitle-2">Valor venda</span>
                      <vuetify-money v-model="valorVenda" :disabled="true" />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="btnCancelar" text @click="closeDialog">
                    <span class="text-capitalize">Cancelar</span>
                  </v-btn>
                  <v-btn
                    :disabled="readOnly"
                    color="primary"
                    :loading="loadingSalvar"
                    @click="salvar"
                  >
                    <span class="text-capitalize">Salvar</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import { PlanoService } from "@/services";

export default {
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function,
      required: true
    },
    closeDialog: {
      type: Function,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    form: {
      valid: false
    },
    rules: {
      required: value => !!value || "Campo obrigatório."
    },
    loadingSalvar: false,
    loading: false
  }),
  computed: {
    valorVenda() {
      return this.item.valorTotal - this.item.valorDesconto;
    }
  },
  methods: {
    salvar() {
      if (this.item.valorMinimo > this.valorVenda) {
        this.$root.showErro(
          "O valor de venda deve ser maior ou igual que R$ " +
            this.asMoney(this.item.valorMinimo)
        );
        return;
      }
      let valorDesconto = 0
      if(!this.item.valorDesconto){
        valorDesconto = 0
      } else {
        valorDesconto = this.item.valorDesconto
      }
      PlanoService.saveValorPlano(
        valorDesconto,
        this.item.valorTotal,
        this.valorVenda,
        this.item.id
      )
        .then(() => {
          this.closeDialog();
          this.callback();
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    }
  }
};
</script>
