<template>
  <v-card-text>
    <v-container>
      <v-row class="pl-6">
        <v-switch
          v-model="hasIngresso"
          inset
          label="Habilita Ingresso"
        />
      </v-row>
      <ConfigImages
        :upload="uploadBannerIngresso"
        title="Imagem do Banner"
        :loading="loading"
        :loadingBanner="loadingBannerIngresso"
        :bannerUrl="configuracaoGeral.bannerIngressoUrl"
      ></ConfigImages>
    </v-container>
    <v-row class="justify-end">
      <v-btn
        :loading="loadingSiteLandpage"
        @click="updateConfigGeral"
        small
        color="primary"
        class="mt-10"
      >
        Salvar
      </v-btn>
    </v-row>
  </v-card-text>
</template>
<script>
import { ConfiguracaoGeralService } from "@/services";
import ConfigImages from "./ConfigImages";

export default {
  components: { ConfigImages },
  data: () => ({
    loading: false,
    configuracaoGeral: null,
    loadingBannerIngresso: false,
    hasIngresso: false,
  }),
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      ConfiguracaoGeralService.getConfiguracaoGeral()
        .then(response => {
          this.configuracaoGeral = response.data;
          this.hasIngresso = response.data.instalacao.habilitaIngresso
        })
        .catch(() =>
          this.$root.showErro(
            "Não foi possível buscar as informações da configuração geral. Por favor, tente novamente mais tarde."
          )
        )
        .finally(() => {
          this.loading = false;
        });
    },
    updateConfigGeral() {
      this.loadingSiteLandpage = true;
      ConfiguracaoGeralService.setConfiguracaoGeral({
        id: this.configuracaoGeral.id,
        habilitaIngresso: this.hasIngresso
      })
        .then(() => {
          this.$root.showSucesso("Configuração atualizada com sucesso!");
        })
        .catch(() => {
          this.$root.showErro(
            "Não foi possível alterar a configuração. Por favor, tente novamente mais tarde."
          );
        })
        .finally(() => {
          this.loadingSiteLandpage = false;
        });
    },
    uploadBannerIngresso(event) {
      if (event && event.target && event.target.files.length > 0) {
        let file = event.target.files[0];
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        this.loadingBannerIngresso = true;
        ConfiguracaoGeralService.uploadBannerIngresso(formData)
          .then(response => {
            this.configuracaoGeral.bannerIngressoUrl = response.data;
          })
          .catch(() =>
            this.$root.showErro(
              "Ocorreu um erro ao enviar a imagem, tente novamente mais tarde."
            )
          )
          .finally(() => {
            this.loadingBannerIngresso = false;
          });
      }
    }
  }
};
</script>
