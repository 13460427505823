<template>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col cols="12" class="col-no-padding">
          <span class="subtitle-2">Planos para Estabelecimentos</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="col-no-padding">
          <v-card elevation="0">
            <v-data-table
              :headers="headers"
              :items="planos"
              :loading="loading"
              class="elevation-0"
              loading-text="Carregando..."
              hide-default-footer
              width="100%"
            >
              <template slot="headerCell" slot-scope="{ header }">
                <span
                  class="subheading font-weight-light text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template v-slot:body="{ items }" ma-5>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item.nome }}</td>
                    <td>{{ item.descricao }}</td>
                    <td>{{ item.periodicidade.descricao }}</td>
                    <td>{{ asMoney(item.valorTotal) }}</td>
                    <td>{{ asMoney(item.valorDesconto) }}</td>
                    <td>{{ asMoney(item.valor) }}</td>
                    <td class="text-right">
                      <v-switch
                        class="ma-0 pa-0"
                        v-model="item.isDisponivel"
                        inset
                        hide-details
                        disabled
                      ></v-switch>
                    </td>
                    <td class="text-right">
                      <v-icon
                        v-if="!item.isSemCustos"
                        class="mr-2"
                        title="Editar"
                        @click="editar(item)"
                        >mdi-pencil</v-icon
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pl-3">
        <v-switch
          v-model="hasDesconto"
          inset
          label="Habilita Desconto"
        />
      </v-row>
      <v-row>
        <v-col cols="4">
          <span class="subtitle-2">Site landpage</span>
          <v-text-field v-model="siteLandpage" :loading="loadingSiteLandpage" />
        </v-col>
      </v-row>
      <ConfigImages
        :upload="uploadBannerDesconto"
        title="Imagem do Banner"
        :loading="loading"
        :loadingBanner="loadingBannerDesconto"
        :bannerUrl="configuracaoGeral.bannerDescontoUrl"
      ></ConfigImages>

      <EditPlano
        v-if="dialog"
        :close-dialog="closeDialog"
        :item="plano"
        :callback="salvar"
      />
    </v-container>
    <v-row class="justify-end">
      <v-btn
        :loading="loadingSiteLandpage"
        @click="salvarSiteLandpage"
        small
        color="primary"
        class="mt-10"
      >
        Salvar
      </v-btn>
    </v-row>
  </v-card-text>
</template>
<script>
import { PlanoService, ConfiguracaoGeralService } from "@/services";
import EditPlano from "./EditPlano";
import ConfigImages from "./ConfigImages";

export default {
  components: { EditPlano, ConfigImages },
  data: () => ({
    loading: false,
    planos: [],
    headers: [
      { text: "Plano", value: "nome" },
      { text: "Descrição", value: "descricao" },
      { text: "Periodicidade", value: "periodicidade" },
      { text: "Valor total", value: "valorTotal" },
      { text: "Valor desconto", value: "valorDesconto" },
      { text: "Valor venda", value: "valor" },
      { text: "Ativo", value: "isDisponivel" },
      { text: "Ações", value: "", align: "right", sortable: false }
    ],
    dialog: false,
    plano: {},
    configuracaoGeral: null,
    siteLandpage: null,
    hasDesconto: false,
    loadingSiteLandpage: false,
    loadingBannerDesconto: false
  }),
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      PlanoService.getAllPlanosEstabelecimento()
        .then(response => {
          this.planos = response.data;
        })
        .catch(() =>
          this.$root.showErro(
            "Não foi possível buscar as informações dos planos. Por favor, tente novamente mais tarde."
          )
        )
        .finally(() => {
          this.loading = false;
        });

      ConfiguracaoGeralService.getConfiguracaoGeral()
        .then(response => {
          this.configuracaoGeral = response.data;
          this.hasDesconto = response.data.instalacao.habilitaCupom
          this.siteLandpage = this.configuracaoGeral.landpageDescontoUrl;
        })
        .catch(() =>
          this.$root.showErro(
            "Não foi possível buscar as informações da configuração geral. Por favor, tente novamente mais tarde."
          )
        );
    },
    editar(item) {
      this.plano = { ...item };
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    salvar() {
      this.closeDialog();
      this.getData();
    },
    salvarSiteLandpage() {
      this.loadingSiteLandpage = true;
      ConfiguracaoGeralService.setConfiguracaoGeral({
        id: this.configuracaoGeral.id,
        landpageDescontoUrl: this.siteLandpage,
        habilitaDesconto: this.hasDesconto
      })
        .then(() => {
          this.$root.showSucesso("Configuração atualizada com sucesso!");
        })
        .catch(() => {
          this.$root.showErro(
            "Não foi possível alterar a configuração. Por favor, tente novamente mais tarde."
          );
        })
        .finally(() => {
          this.loadingSiteLandpage = false;
        });
    },
    uploadBannerDesconto(event) {
      if (event && event.target && event.target.files.length > 0) {
        let file = event.target.files[0];
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        this.loadingBannerDesconto = true;
        ConfiguracaoGeralService.uploadBannerDesconto(formData)
          .then(response => {
            this.configuracaoGeral.bannerDescontoUrl = response.data;
          })
          .catch(() =>
            this.$root.showErro(
              "Ocorreu um erro ao enviar a imagem, tente novamente mais tarde."
            )
          )
          .finally(() => {
            this.loadingBannerDesconto = false;
          });
      }
    }
  }
};
</script>
