<template>
  <v-container pa-5 fluid>
    <h2 class="mb-5">Configurações</h2>

    <v-card class="pa-3">
      <v-tabs v-model="tab" active-class="tabselected">
        <v-tab v-for="item in getItems()" :key="item.tab" class="text-capitalize">
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item class="borderTopRed pt-3">
          <v-card flat>
            <!-- <ConfigClientDefault :cliente="cliente"></ConfigClientDefault> -->
          </v-card>
        </v-tab-item>
        <v-tab-item class="borderTopRed pt-3">
          <v-card flat>
            <ConfigImages :upload="uploadBannerApp" :excluir="excluirBannerApp" title="Banner de Capa – APP"
              :loading="loading" :loadingBanner="loadingBanner" :bannerUrl="configuracaoGeral.bannerAppUrl">
            </ConfigImages>
            <ConfigImages :upload="uploadBanner" :excluir="excluirBanner" title="Banner de Capa – Admin"
              :loading="loading" :loadingBanner="loadingBanner" :bannerUrl="configuracaoGeral.bannerUrl"></ConfigImages>
            <ConfigImages :upload="uploadLogo" :excluir="excluirLogo"
              :title="isInfluencers() ? 'Banner de Seleção' : 'Logo Oficial?'" :loading="loading"
              :loadingBanner="loadingBanner" :bannerUrl="configuracaoGeral.logoUrl"></ConfigImages>
          </v-card>
        </v-tab-item>
        <v-tab-item class="borderTopRed pt-3">
          <v-card flat>
            <ConfigPlanos></ConfigPlanos>
          </v-card>
        </v-tab-item>
        <v-tab-item class="borderTopRed pt-3" v-if="habilitaDesconto">
          <v-card flat>
            <ConfigDescontos></ConfigDescontos>
          </v-card>
        </v-tab-item>
        <v-tab-item class="borderTopRed pt-3" v-if="habilitaIngresso">
          <v-card flat>
            <ConfigIngressos></ConfigIngressos>
          </v-card>
        </v-tab-item>
        <v-tab-item class="borderTopRed pt-3">
          <v-card flat>
            <ConfigGeral></ConfigGeral>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import ConfigImages from "./ConfigImages.vue";
// import ConfigClientDefault from "./ConfigClientDefault.vue";
import ConfigPlanos from "./ConfigPlanos.vue";
import ConfigGeral from "./ConfigGeral.vue";
import ConfigDescontos from "./ConfigDescontos.vue";
import ConfigIngressos from "./ConfigIngressos.vue";
import { ConfiguracaoGeralService, ClientService } from "@/services";
export default {
  components: {
    ConfigImages,
    // ConfigClientDefault,
    ConfigPlanos,
    ConfigGeral,
    ConfigDescontos,
    ConfigIngressos
  },
  data() {
    return {
      tab: null,
      loading: false,
      loadingBanner: false,
      formData: null,
      file: {},
      configuracaoGeral: {
        bannerUrl: null,
        bannerAppUrl: null,
        logoUrl: null
      },
      cliente: {}
    };
  },
  computed: {
    habilitaMarketplace() {
      return this.$store.getters["instalacao/getInstalacao"]
        .habilitaMarketplace;
    },
    habilitaIngresso() {
      return this.$store.getters["instalacao/getInstalacao"].habilitaIngresso;
    },
    habilitaDesconto() {
      console.log(this.$store.getters["instalacao/getInstalacao"]), "teste de todo getter";
      return this.$store.getters["instalacao/getInstalacao"].habilitaCupom;
    }
  },
  beforeMount() {
    this.loading = true;

    let promiseClient = ClientService.getDefault().then(response => {
      this.cliente = response.data;
    });

    let promiseConfiguracaoGeral = ConfiguracaoGeralService.getConfiguracaoGeral().then(
      response => {
        this.configuracaoGeral = response.data;
        console.log(response.data, "Config")
      }
    );

    Promise.all([promiseClient, promiseConfiguracaoGeral]).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    getItems() {
      let items = [
        {
          tab: "Dados Básicos",
          show: true
        },
        {
          tab: "Imagens",
          show: true
        },
        {
          tab: "Planos",
          show: true
        },
        {
          tab: "Descontos",
          show: this.habilitaDesconto
        },
        {
          tab: "Ingressos",
          show: this.habilitaIngresso
        },
        {
          tab: "Geral",
          show: true
        }
      ];
      return items.filter(item => {
        return item.show;
      });
    },
    uploadBannerApp(event) {
      if (event && event.target && event.target.files.length > 0) {
        let file = event.target.files[0];
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        this.loadingBanner = true;
        ConfiguracaoGeralService.uploadBannerApp(formData)
          .then(response => {
            this.configuracaoGeral.bannerAppUrl = response.data;
          })
          .catch(() =>
            this.$root.showErro(
              "Ocorreu um erro ao enviar a imagem, tente novamente mais tarde."
            )
          )
          .finally(() => {
            this.loadingBanner = false;
          });
      }
    },
    uploadBanner(event) {
      if (event && event.target && event.target.files.length > 0) {
        let file = event.target.files[0];
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        this.loadingBanner = true;
        ConfiguracaoGeralService.uploadBanner(formData)
          .then(response => {
            this.configuracaoGeral.bannerUrl = response.data;
          })
          .catch(() =>
            this.$root.showErro(
              "Ocorreu um erro ao enviar a imagem, tente novamente mais tarde."
            )
          )
          .finally(() => {
            this.loadingBanner = false;
          });
      }
    },
    uploadLogo(event) {
      if (event && event.target && event.target.files.length > 0) {
        let file = event.target.files[0];
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        this.loadingBanner = true;
        ConfiguracaoGeralService.uploadLogo(formData)
          .then(response => {
            this.configuracaoGeral.logoUrl = response.data;
          })
          .catch(() =>
            this.$root.showErro(
              "Ocorreu um erro ao enviar a imagem, tente novamente mais tarde."
            )
          )
          .finally(() => {
            this.loadingBanner = false;
          });
      }
    },
    excluirLogo() {
      this.loadingBanner = true;
      ConfiguracaoGeralService.deleteLogo()
        .then(response => {
          response.data;
          this.configuracaoGeral.logoUrl = "";
        })
        .catch(() =>
          this.$root.showErro(
            "Ocorreu um erro ao enviar a imagem, tente novamente mais tarde."
          )
        )
        .finally(() => {
          this.loadingBanner = false;
        });

    },
    excluirBanner() {
      this.loadingBanner = true;
      ConfiguracaoGeralService.deleteBanner()
        .then(response => {
          response.data;
          this.configuracaoGeral.bannerUrl = "";
        })
        .catch(() =>
          this.$root.showErro(
            "Ocorreu um erro ao enviar a imagem, tente novamente mais tarde."
          )
        )
        .finally(() => {
          this.loadingBanner = false;
        });

    },
    excluirLogoBannerApp() {
      this.loadingBanner = true;
      ConfiguracaoGeralService.deleteBannerApp()
        .then(response => {
          response.data;
          this.configuracaoGeral.bannerAppUrl = "";
        })
        .catch(() =>
          this.$root.showErro(
            "Ocorreu um erro ao enviar a imagem, tente novamente mais tarde."
          )
        )
        .finally(() => {
          this.loadingBanner = false;
        });

    }
  }
};
</script>

<style>
.tabselected {
  background: var(--v-primary-base);
  color: var(--v-white-base) !important;
  border-radius: 5px 5px 0px 0px;
}

.borderTopRed {
  border-top: 2px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}
</style>
