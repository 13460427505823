<template>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col cols="12" class="col-no-padding">
          <span class="subtitle-2">Plano de Assinatura de Conteúdo</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="col-no-padding">
          <v-card elevation="0">
            <v-data-table
              :headers="headers"
              :items="planos"
              :loading="loading"
              class="elevation-0"
              loading-text="Carregando..."
              hide-default-footer
              width="100%"
            >
              <template slot="headerCell" slot-scope="{ header }">
                <span
                  class="subheading font-weight-light text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template v-slot:body="{ items }" ma-5>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item.nome }}</td>
                    <td>{{ item.descricao }}</td>
                    <td>{{ item.periodicidade.descricao }}</td>
                    <td>{{ asMoney(item.valorTotal) }}</td>
                    <td>{{ asMoney(item.valorDesconto) }}</td>
                    <td>{{ asMoney(item.valor) }}</td>
                    <td class="text-right">
                      <v-switch
                        class="ma-0 pa-0"
                        v-model="item.isDisponivel"
                        inset
                        hide-details
                        disabled
                      ></v-switch>
                    </td>
                    <td class="text-right">
                      <v-icon
                        v-if="!item.isSemCustos"
                        class="mr-2"
                        title="Editar"
                        @click="editar(item)"
                        >mdi-pencil</v-icon
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <span class="subtitle-2">Conteúdo Exclusivo</span>
          <vuetify-money
            label="Valor padrão para conteúdo exclusivo"
            v-model="valorConteudoExclusivo"
            :properties="{
              loading: loadingConteudoExclusivo
            }"
          />
        </v-col>
        <v-col>
          <v-btn
            :loading="loadingConteudoExclusivo"
            @click="salvarConteudoExclusivo"
            small
            color="primary"
            class="mt-10 "
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <span class="subtitle-2">Gorjeta</span>
          <vuetify-money
            label="Valor mínimo para gorjetas"
            v-model="valorDefaultGorjeta"
            :properties="{
              loading: loadingGorjeta
            }"
          />
        </v-col>
        <v-col>
          <v-btn
            :loading="loadingGorjeta"
            @click="salvarDefaultGorjeta"
            small
            color="primary"
            class="mt-10"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>

      <EditPlano
        v-if="dialog"
        :close-dialog="closeDialog"
        :item="plano"
        :callback="salvar"
      />
    </v-container>
  </v-card-text>
</template>
<script>
import { PlanoService } from "@/services";
import EditPlano from "./EditPlano";

export default {
  components: { EditPlano },
  data: () => ({
    loading: false,
    planos: [],
    headers: [
      { text: "Plano", value: "nome" },
      { text: "Descrição", value: "descricao" },
      { text: "Periodicidade", value: "periodicidade" },
      { text: "Valor total", value: "valorTotal" },
      { text: "Valor desconto", value: "valorDesconto" },
      { text: "Valor venda", value: "valor" },
      { text: "Ativo", value: "isDisponivel" },
      { text: "Ações", value: "", align: "right", sortable: false }
    ],
    dialog: false,
    plano: {},
    valorConteudoExclusivo: null,
    valorMinimoConteudoExclusivo: null,
    valorDefaultGorjeta: null,
    valorMinimoDefaultGorjeta: null,
    loadingConteudoExclusivo: false,
    loadingGorjeta: false,
    valorPlanoConteudoExclusivoId: null,
    valorPlanoGorjetaId: null
  }),
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      PlanoService.getAllPlanos()
        .then(response => {
          this.planos = response.data;
        })
        .catch(() =>
          this.$root.showErro(
            "Não foi possível buscar as informações dos planos. Por favor, tente novamente mais tarde."
          )
        )
        .finally(() => {
          this.loading = false;
        });

      PlanoService.getValorConteudoExclusivo()
        .then(response => {
          this.valorConteudoExclusivo = response.data.valor;
          this.valorMinimoConteudoExclusivo = response.data.valorMinimo;
          this.valorPlanoConteudoExclusivoId = response.data.valorPlanoId;
        })
        .catch(() =>
          this.$root.showErro(
            "Não foi possível buscar as informações do conteúdo exclusivo. Por favor, tente novamente mais tarde."
          )
        );

      PlanoService.getValorDefaultGorjeta()
        .then(response => {
          this.valorDefaultGorjeta = response.data.valor;
          this.valorMinimoDefaultGorjeta = response.data.valorMinimo;
          this.valorPlanoGorjetaId = response.data.valorPlanoId;
        })
        .catch(() =>
          this.$root.showErro(
            "Não foi possível buscar as informações da gorjeta. Por favor, tente novamente mais tarde."
          )
        );
    },
    editar(item) {
      this.plano = { ...item };
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    salvar() {
      this.closeDialog();
      this.getData();
    },
    salvarConteudoExclusivo() {
      if (this.valorConteudoExclusivo < this.valorMinimoConteudoExclusivo) {
        this.$root.showErro(
          "O valor para conteúdo exclusivo não pode ser menor que o valor mínimo de: R$ " +
            this.asMoney(this.valorMinimoConteudoExclusivo)
        );
        return;
      }
      this.loadingConteudoExclusivo = true;
      PlanoService.saveValorPlano(
        null,
        null,
        this.valorConteudoExclusivo,
        this.valorPlanoConteudoExclusivoId
      )
        .then(() => {
          this.$root.showSucesso("Valor padrão salvo com sucesso.");
        })
        .finally(() => {
          this.loadingConteudoExclusivo = false;
        });
    },
    salvarDefaultGorjeta() {
      if (this.valorDefaultGorjeta < this.valorMinimoDefaultGorjeta) {
        this.$root.showErro(
          "O valor para gorjeta não pode ser menor que o valor mínimo de: R$ " +
            this.asMoney(this.valorMinimoDefaultGorjeta)
        );
        return;
      }
      this.loadingGorjeta = true;
      PlanoService.saveValorPlano(
        null,
        null,
        this.valorDefaultGorjeta,
        this.valorPlanoGorjetaId
      )
        .then(() => {
          this.$root.showSucesso("Valor mínimo da gorjeta salvo com sucesso.");
        })
        .finally(() => {
          this.loadingGorjeta = false;
        });
    }
  }
};
</script>
