<template>
  <v-card-text>
    <v-container>
      <v-row class="mb-8">
        <span class="text-subtitle-2 font-weight-bold">Aplicativo</span>
      </v-row>
      <v-row>
        <v-switch
          class="ma-0 pa-0"
          v-model="isPermiteSelecao"
          inset
          hide-details
          label="Habilitar o perfil para seleção no App"
          :loading="loading"
        >
        </v-switch>
      </v-row>
      <v-row class="mt-6">
        <v-text-field
          v-model="nomeSelecaoApp"
          label="Nome para seleção no APP"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          v-model="emailRecebimentoApp"
          label="E-mail para recebimento de mensagens do APP"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field v-model="boasVindas" label="Bio"></v-text-field>
      </v-row>
      <v-card-actions class="mt-4 mr-n5">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          type="submit"
          :loading="loading"
          @click="salvar()"
        >
          <span class="text-capitalize">Salvar</span>
        </v-btn>
      </v-card-actions>
      <v-divider class="mt-8 mb-8" />
      <v-row v-if="habilitaUsuarioPadrao">
        <span class="text-subtitle-2 font-weight-bold">Usuário Padrão</span>
      </v-row>
      <v-row class="mt-2" v-if="habilitaUsuarioPadrao">
        <v-form
          ref="form"
          v-model="valid"
          :disabled="users.length >= 1"
          @submit.prevent="salvarUsuario"
          class="w-full d-flex allign-center"
        >
          <v-text-field
            v-model="userApp"
            :rules="[rules.required, rules.email]"
            label="Usuário padrão"
          ></v-text-field>
          <v-btn
            icon
            color="primary"
            type="submit"
            :disabled="users.length >= 1"
            :loading="loading"
          >
            <v-icon class="text-capitalize">mdi-plus</v-icon>
          </v-btn>
        </v-form>
      </v-row>
      <v-row v-if="habilitaUsuarioPadrao" >
        <div
          v-for="(user, i) in users"
          :key="i"
          class="d-flex w-full flex-wrap mb-4 mt-4"
        >
          <div class="w-full d-flex justify-space-between flex-wrap">
            <span>{{ user.email }}</span>
            <v-btn v-if="habilitaExclusao" icon @click="openCancelModal(user)">
              <v-icon color="error"> mdi-account-cancel-outline</v-icon>
            </v-btn>
          </div>
          <span class="w-full">
            <v-divider class="w-full" />
          </span>
        </div>
      </v-row>
      <v-row class="w-full d-flex justify-end mt-2"> </v-row>
    </v-container>
  </v-card-text>
</template>
<script>
import {
  ConfiguracaoGeralService,
  ClientService,
  UsuarioExternoService,
} from "@/services";

export default {
  data: () => ({
    loading: false,
    isPermiteSelecao: false,
    configuracaoGeral: null,
    habilitaExclusao: false,
    boasVindas: "",
    nomeSelecaoApp: "",
    emailRecebimentoApp: "",
    editaEmail: true,
    valid: false,
    userApp: null,
    clienteId: null,
    users: [],
    userToDelete: null,
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Endereço de email inválido.";
      },
    },
  }),
  computed: {
    habilitaUsuarioPadrao() {
      return this.$store.getters["instalacao/getInstalacao"].habilitaUsuarioPadrao;
    },
  },
  beforeMount() {
    this.loading = true;
    UsuarioExternoService.getUsuarioVinculado().then((response) => {
      this.users = response.data;
    });
    ClientService.getDefault().then((response) => {
      this.clienteId = response.data.classeCliente.id;
    });
    ConfiguracaoGeralService.getConfiguracaoGeral()
      .then((response) => {
        this.isPermiteSelecao = response.data.isPermiteSelecao;
        if (this.isPermiteSelecao) {
          this.editaEmail = false;
        }
        this.emailRecebimentoApp = response.data.enderecoEmailPadrao;
        this.nomeSelecaoApp = response.data.instalacao.descricao;
        this.configuracaoGeral = response.data;
        this.boasVindas = response.data.boasVindas;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getUsuariosVinculados() {
      UsuarioExternoService.getUsuarioVinculado().then((response) => {
        this.users = response.data;
      });
    },
    openCancelModal(user) {
      console.log(user);
      this.$root.showConfirmDialog(
        "Está ação não poderá ser desfeita! Deseja continuar?",
        "Atenção!",
        this.cancelUser,
        user
      );
    },

    cancelUser(user) {
      this.loading = true;
      let payload = {
        email: user.email,
        nome: user.nome,
      };
      UsuarioExternoService.blockUsuarioVinculado(payload)
        .then((response) => {
          this.$root.showSucesso(response.data);
          this.getUsuariosVinculados();
        })
        .catch((e) => {
          this.$root.showErro(e.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    salvar() {
      this.loading = true;
      ConfiguracaoGeralService.setConfiguracaoGeral({
        id: this.configuracaoGeral.id,
        isPermiteSelecao: this.isPermiteSelecao,
        descricao: this.nomeSelecaoApp,
        email: this.emailRecebimentoApp,
        boasVindas: this.boasVindas,
      })
        .then(() => {
          this.$root.showSucesso("Configuração atualizada com sucesso!");
          if (this.isPermiteSelecao) {
            this.editaEmail = false;
          } else {
            this.editaEmail = true;
          }
        })
        .catch(() => {
          this.$root.showErro(
            "Não foi possível alterar a configuração de perfil de seleção"
          );
          this.isPermiteSelecao = !this.isPermiteSelecao;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    salvarUsuario() {
      if (!this.$refs.form.validate())
        return this.$root.showErro("Digite um Email Válido");
      this.loading = true;
      let payload = {
        email: this.userApp,
        clienteId: this.clienteId,
      };
      UsuarioExternoService.newUsuarioVinculado(payload)
        .then((response) => {
          this.userApp = null;
          this.$refs.form.reset();
          if (response)
            this.$root.showSucesso("Usuário Cadastrado com Sucesso");
          this.getUsuariosVinculados();
        })
        .catch((e) => {
          this.$root.showErro(e.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.w-full {
  width: 100%;
}
</style>
