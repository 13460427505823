<template>
  <v-row>
    <template v-if="loading">
      <v-row no-gutters style="height: 90vh;" class="text-center">
        <v-col align-self="center">
          <v-progress-circular indeterminate color="primary" :size="70" />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-card elevation="0">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align-self="center" cols="2">
                <banner class="banner" :loading="loadingBanner" :bannerUrl="bannerUrl ? bannerUrl : ''" />
              </v-col>
              <v-col>
                <v-card class="pt-3" elevation="0" max-height="200">
                  <v-row>
                    <div class="ml-6">
                      <v-row class="title mt-0 ml-0">{{ title }}
                        <v-form enctype="multipart/form-data" class="ml-4">
                          <label style="cursor: pointer" class="btn-edit-banner" title="Alterar imagem">
                            <input style="display: none" type="file" @change="upload($event)" />
                            <v-icon color="primary">mdi-pencil-outline</v-icon>
                          </label>
                          <label style="cursor: pointer" class="btn-edit-banner" title="Delete imagem">

                            <v-icon color="primary" @click="excluir()">mdi-delete</v-icon>
                          </label>
                        </v-form>

                      </v-row>

                      <br />
                      <!--<span>Tamanho X por Y (pixel)</span>
                      <br />-->
                      <!-- <span>Procure utilizar um fundo branco</span> -->
                    </div>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </template>
  </v-row>
</template>
<script>
import Banner from "@/components/banner.vue";

export default {
  components: { Banner },
  props: {
    title: {
      type: String
    },
    callback: {
      type: Function
    },
    closeDialog: {
      type: Function
    },
    upload: {
      type: Function
    },
    excluir: {
      type: Function
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingBanner: {
      type: Boolean,
      default: false
    },
    bannerUrl: {
      type: String
    }
  }
};
</script>
<style>
.banner {
  max-height: 200px;
}

.container-banner {
  position: relative;
}

.btn-edit-banner {
  background-color: var(--v-white-base);
  border-radius: 30px;
}

.btn-edit-banner:hover {
  color: var(--v-primary-base);
  background-color: var(--v-ice-base);
}
</style>
